// axios.get(`${process.env.MIX_API_URL}/v2/external/dvsa/${this.claim.plate}`)
//                 .then(response => {
//                     this.claim.make = response.data.vehicle.make;
//                     this.claim.model = response.data.vehicle.model;
//                     this.claim.colour = response.data.vehicle.colour;
//                     this.searchingMMC = false;
//                 })
//                 .catch(error => {
//                     this.$error("Unable to find the Make, Model and Colour for this vehicle.", error);
//                     this.searchingMMC = false;
//                 });

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class ExternalService {
  getMMC(plate) {
    return axios.get(
      `https://api.varsanpr.com/api/vehicle/external/dvsa/${plate}`,
      { headers: authHeader() }
    );
  }

  getAddressByPostcode(postcode) {
    return axios.get(`https://api.varsanpr.com/api/misc/address/${postcode}`, {
      headers: authHeader(),
    });
  }
}

export default new ExternalService();
